:root { 
  --black: #000000;
  --blue: #297aff;
  --blue-2: #297bff;
  --dark-blue: #280eca;
  --dove-gray: #6f6f6f;
  --mobster: #817e8c;
  --onyx: #3a3a3a;
  --quick-silver: #a8a8a8;
  --spun-pearl: #adabb4;
  --turquoise: #28d8ff;
  --white: #ffffff;
 
  --font-size-l: 18px;
  --font-size-m: 16px;
  --font-size-s: 14px;
  --font-size-xl: 20px;
  --font-size-xxl: 25px;
  --font-size-xxxl: 48px;
  --font-size-xxxxl: 60px;
 
  --font-family-font_awesome_5_brands-regular: "Font Awesome 5 Brands-Regular", Helvetica;
  --font-family-font_awesome_5_free-regular: "Font Awesome 5 Free-Regular", Helvetica;
  --font-family-jost: "Jost", Helvetica;
  --font-family-jumunaomj-regular: "JumunaOMJ-Regular", Helvetica;
  --font-family-montserrat: "Montserrat", Helvetica;
  --font-family-open_sans: "Open Sans", Helvetica;
}
